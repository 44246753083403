<script>
import {
  SuawMainContent,
  SuawSummary,
  SuawTextInput,
  SuawInputGroup,
  SuawFileUpload,
  SuawLabel,
  SuawRadio,
  SuawDropdown,
  SuawButton,
  SuawForm,
  SuawTextArea
} from "@suaw/suaw-component-library";
import { required, maxLength } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import * as UserApi from "../../api.js";
import { convertFileToBase64String } from "@/utils/formatting/files.js";
import { getAvatarColorOptions } from "../../common.js";

export default {
  name: "DashboardProfileEdit",
  components: {
    SuawMainContent,
    SuawTextInput,
    SuawSummary,
    SuawInputGroup,
    SuawFileUpload,
    SuawLabel,
    SuawRadio,
    SuawDropdown,
    SuawButton,
    SuawForm,
    SuawTextArea
  },
  data() {
    return {
      avatarColorOptions: getAvatarColorOptions(),
      editForm: {
        userId: "",
        email: "",
        countryAbbrev: "",
        postalCode: "",
        unitSystem: "",
        avatarUrl: "",
        avatarColor: "",
        firstName: "",
        lastName: "",
        isLastNameHidden: null,
        descriptionJson: { type: "doc", content: [{ type: "paragraph" }] },
        avatarType: "color"
      }
    };
  },
  computed: {
    originalFirstName() {
      return this.$auth.user.first_name;
    },
    originalLastName() {
      return this.$auth.user.last_name;
    },
    showImageUpload() {
      return this.editForm.avatarType === "image";
    }
  },
  validations: {
    editForm: {
      firstName: {
        required,
        maxLength: maxLength(40)
      },
      lastName: {
        required,
        maxLength: maxLength(40)
      }
    }
  },
  mounted() {
    this.editForm = {
      userId: this.$auth.user.id,
      email: this.$auth.user.email,
      countryAbbrev: this.$auth.user.country_abbrev,
      unitSystem: this.$auth.user.unit_system,
      postalCode: this.$auth.user.postal_code,
      avatarColor: this.$auth.user.avatar_color,
      firstName: this.$auth.user.first_name,
      lastName: this.$auth.user.last_name,
      isLastNameHidden: this.$auth.user.is_last_name_hidden,
      descriptionJson: this.$auth.user.description_json,
      avatarType: this.$auth.user.avatar_url ? "image" : "color"
    };
  },
  methods: {
    validateErrors,
    formFieldState,
    async uploadUserAvatar() {
      const { userId, avatarUrl } = this.editForm;
      const result = await UserApi.uploadUserAvatar(userId, avatarUrl);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async updateUserProfile() {
      const { userId, email, firstName, lastName, isLastNameHidden, countryAbbrev, postalCode, avatarColor, unitSystem, descriptionJson } = this.editForm;
      const result = await UserApi.updateUserProfile(
        userId,
        email,
        firstName,
        lastName,
        isLastNameHidden,
        countryAbbrev,
        postalCode,
        avatarColor,
        unitSystem,
        null,
        descriptionJson
      );
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    onCancelClicked() {
      this.$router.push({ name: "DashboardProfileView" });
    },
    async onConfirmClicked() {
      this.$v.editForm.$touch();
      if (this.$v.editForm.$invalid) {
        this.$root.$emit("universal-error-message", "Please complete required fields of form.");
      } else {
        if (this.editForm.avatarUrl) {
          const uploadUserAvatarResult = await this.uploadUserAvatar();
          if (!uploadUserAvatarResult.success) {
            return;
          }
        }

        const updateUserProfileResult = await this.updateUserProfile();
        if (!updateUserProfileResult.success) {
          return;
        }

        try {
          this.$router.push({ name: "DashboardProfileView" });
          window.location.reload(); //forcing reload so when routed to profile view the changes will be reflected
        } catch (error) {
          this.$root.$emit("universal-error-message", "Unable to update your profile. Try again or contact support if issues persist."); //!!not exactly true though is it?
        }
      }
    },
    async convertFile(file) {
      try {
        const base64Data = await convertFileToBase64String(file);
        this.editForm.avatarUrl = base64Data;
      } catch (error) {
        this.$root.$emit("universal-error-message", "Error converting file to Base64");
      }
    },
    handleAvatarColorSelect(avatar) {
      this.editForm.avatarColor = avatar.avatarType;
    },
    redirectToSignIn() {
      this.$router.push({
        name: "SignIn",
        query: {
          redirectUrl: `${this.$route.fullPath}`
        }
      });
    }
  }
};
</script>

<template>
  <SuawMainContent size="medium">
    <SuawForm v-bind="$props">
      <template #form>
        <SuawSummary summary-type="text" heading="Edit Profile" />
        <SuawTextInput
          v-model="editForm.firstName"
          type="text"
          :placeholder="originalFirstName"
          label="First Name:"
          is-required
          :state="formFieldState($v, 'editForm', 'firstName')"
          :error-message="validateErrors($v.editForm.firstName, 'First name')"
          @blur="$v.editForm.firstName.$touch()"
        />
        <SuawTextInput
          v-model="editForm.lastName"
          type="text"
          :placeholder="originalLastName"
          label="Last Name:"
          is-required
          :state="formFieldState($v, 'editForm', 'lastName')"
          :error-message="validateErrors($v.editForm.lastName, 'Last name')"
          @blur="$v.editForm.lastName.$touch()"
        />
        <SuawLabel label-text="Show As:" size="small" />
        <SuawInputGroup direction="row" field-one-size="1" field-two-size="10">
          <SuawRadio id="full" v-model="editForm.isLastNameHidden" name="chooseName" label="Full Name" :native-value="false" />
          <SuawRadio id="firstLast" v-model="editForm.isLastNameHidden" name="chooseName" label="First Name & Last Initial" :native-value="true" />
        </SuawInputGroup>
        <SuawTextArea id="eventDesc" v-model="editForm.descriptionJson" label="About You:" placeholder="Your Bio" use-tip-tap />
        <SuawLabel label-text="Avatar Style:" size="small" />
        <SuawInputGroup direction="row" field-one-size="1" field-two-size="10">
          <SuawRadio id="color" v-model="editForm.avatarType" name="chooseAvatar" label="Color" native-value="color" />
          <SuawRadio id="image" v-model="editForm.avatarType" name="chooseAvatar" label="Image" native-value="image" />
        </SuawInputGroup>
        <SuawInputGroup direction="column">
          <SuawFileUpload v-if="showImageUpload" upload-title="Upload User Image" @file-selected="convertFile" />
          <SuawDropdown
            v-else
            v-model="editForm.avatarColor"
            dropdown-button-size="medium"
            :items="avatarColorOptions"
            dropdown-type="mutable"
            button-type="ghost-outline"
            button-class-name="suaw-dropdown__button--fill"
            label="Avatar Color"
            @item-click="handleAvatarColorSelect"
          />
        </SuawInputGroup>
      </template>
    </SuawForm>
    <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true">
      <SuawButton size="large" type="secondary-outline" button-text="Cancel" @click="onCancelClicked" />
      <SuawButton size="large" type="primary" button-text="Confirm Changes" @click="onConfirmClicked" />
    </SuawInputGroup>
  </SuawMainContent>
</template>
