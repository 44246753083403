export function getAvatarColorOptions() {
  return [
    { text: "Blue", avatarType: "blue", avatarContent: "BL" },
    { text: "Cyan", avatarType: "cyan", avatarContent: "CY" },
    { text: "Green", avatarType: "green", avatarContent: "GR" },
    { text: "Light Blue", avatarType: "light-blue", avatarContent: "LB" },
    { text: "Light Cyan", avatarType: "light-cyan", avatarContent: "LC" },
    { text: "Light Gray", avatarType: "light-gray", avatarContent: "LG" },
    { text: "Light Green", avatarType: "light-green", avatarContent: "LG" },
    { text: "Light Orange", avatarType: "light-orange", avatarContent: "LO" },
    { text: "Light Purple", avatarType: "light-purple", avatarContent: "LP" },
    { text: "Light Red", avatarType: "light-red", avatarContent: "LR" },
    { text: "Orange", avatarType: "orange", avatarContent: "OR" },
    { text: "Purple", avatarType: "purple", avatarContent: "PU" },
    { text: "Red", avatarType: "red", avatarContent: "RE" }
  ];
}
